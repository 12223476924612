.about {
    padding: 4rem 0px 5rem;
    overflow: auto;
    .title {
        font-weight: bold;
        font-size: 2.5rem;
        padding: 50px 0 20px 0;
    }
    p {
        line-height: 1.6;
        padding: 20px 0;
        font-size: 1.3rem;
    }
    .about-section {
        margin: 0 10%;
    }
}

@media screen and (max-width:912px){
    .about {
        padding: 1rem 0px 5rem;
    }
}