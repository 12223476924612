.marketplace {
    .inner-container {
      display: block;  
    }
    
    header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        .logo img{
            width: 100px;
            margin: 0px 20px;
        }
        .wallet-address {
            margin-left: 15px;
        }
        .btn {
            background-color: #0F0E35;
            color: #fff;
            border-color: #0F0E35;
            padding: 6px 16px;
            white-space: nowrap;
            font-size: 13px;
            &:focus {
                color: #fff;
                background-color: #050349;
                border-color: #050349;
                box-shadow: 0 0 0 0.25rem rgba(49, 63, 253, 0.5);
            }
            &:hover {
                color: white;
                background-color: #06036d;
            }
        }  
    }
    .text-mint {
        color: white;
        font-size: 23px;
        margin-bottom: 35px;
        text-align: center;
        font-weight: bold;
    }
    .mint {
        display: flex;
        justify-content: center;
        .input-spinners {
            margin: 0 auto;
            .mint-count {
                font-size: 30px;
                margin: 0 auto;
                background: white;
                width: 100px;
                padding: 10px;
                border-radius: 10px;
                box-shadow: inset 0px 0px 5px 2px rgb(77, 77, 77);
            }
            span {
                display: table;
                margin: 0 auto;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                 -khtml-user-select: none; /* Konqueror HTML */
                   -moz-user-select: none; /* Old versions of Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently
                                              supported by Chrome, Edge, Opera and Firefox */
            }
            .btn-count {
                background-color: #98C8F4;
                color: #050349;
                border-color: #98C8F4;
                padding: 0px 10px;
                margin: 10px;
                font-size: 20px;
                &:focus {
                    color: #fff;
                    background-color: #050349;
                    border-color: #050349;
                    box-shadow: 0 0 0 0.25rem rgba(49, 63, 253, 0.5);
                }
                &:hover {
                    color: white;
                    background-color: #06036d;
                    border-color: #050349;
                }
            }
        }
        .btn-input-spinners {
            display: flex;
            justify-content: center;
        }
        .btn-mint {
            background-color: #0F0E35;
            color: #fff;
            border-color: #0F0E35;
            padding: 15px 70px;
            white-space: nowrap;
            &:focus {
                color: #fff;
                background-color: #050349;
                border-color: #050349;
                box-shadow: 0 0 0 0.25rem rgba(49, 63, 253, 0.5);
            }
            &:hover {
                color: white;
                background-color: #06036d;
            }
        }  
    }    

    .hide {
        display: none !important;
    }
}

