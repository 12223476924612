.marketplace {
    margin: 0 auto;
    z-index: 20;
    background-color: transparent;
    top: 10%;
    border: 1px white solid;
    box-shadow: 0px 10px 15px 2px #2e2e2e;

    #video_section {
        display:table;
        width:auto;
        position:relative;
        min-height: 500px;
        background: black;
        width: 100%;
    }

    #bgvid {
        z-index: -2;
        margin-bottom: -10px;
        margin-top: -10px;
        width: 100%;
        height: 94vh;
        object-fit: cover;
        top: 0;
        left: 0;
        pointer-events: none;
        @extend .fade-in-image;
    }
    
    .inner-container{
        z-index: 1;
        position: absolute;
        width:50%;
        height:50%;
        left:0%;
        right:0%;
        top:0%;
        bottom:0%;
        margin:auto;
        background-size:contain;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .coming-soon {
            font-size: 5rem;
            color: white;
            font-weight: bold;
            align-items: center;
            display: flex;
            flex-direction: column;
            .logo img {
                width: 180px;  
                margin-bottom: 50px;
            }
            .text {
                font-size: 1.4rem;
                font-weight: 300;
                color: white;
                width: 100%;
                margin: 40px 0;
                text-align: center;
            }
            .coming-soon-text {
                line-height: 78px;
                text-align: center;
            }
        }
    }
    
    @media (max-width: 1068px) {
        .logo {
            display: block;
        }

        .inner-container .coming-soon .coming-soon-text {
            line-height: 58px;
            text-align: center;
            font-size: 3.5rem;
        }
    }

    @media (min-aspect-ratio: 16/9) {
        #bgvid {
          width: 100%;
          height: auto;
        }
        .text {
            font-size: 1.2rem;
            font-weight: 300;
            color: white;
            width: 75%;
            text-align: center;
        }
    }

    .fade-in-image {
        animation: fadeIn 8s;
        -webkit-animation: fadeIn 8s;
        -moz-animation: fadeIn 5s;
        -o-animation: fadeIn 5s;
        -ms-animation: fadeIn 5s;
    }
    
    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-moz-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-o-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-ms-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
}