.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  @media (max-width: 768px) {
    .logo {
      display: none;
    }

    .App .App-header nav {
      padding-top: 30px;
    }
  }
  
  .App-header {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .links {
        display: flex;
        ul.social-buttons li a {
            padding: 0;
        }
    }
    nav {
      width: 85%;
      margin: 0 auto;
      a {
        text-decoration: none;
        padding: 5px 15px;
        color: rgb(80, 80, 80);
        white-space: nowrap;
        font-size: 18px;
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        border-radius: 50px;
        font-weight: bold;
        color: rgb(80, 80, 80);
        float: right;
        &:hover {
          background: #efefef;
        }
      }
      img {
        width: 150px;
      }
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  
  