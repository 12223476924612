.terms {
    margin: 0 10%;
    header {
        margin: 50px 0;
        img {
            width: 150px;
            margin: 0 auto;
        }
    }
}

a.terms {
    display: none;
}