.Home {
  min-height: 750px;
}

.line-img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.right-section {
  z-index: 2;
  h2 {
    font-size: 60px;
    font-weight: bold;
    text-align: left;
    line-height: 1.1;
  }
  mark {
    display:inline-block;
    background-color: #98C8F4;
    color: white;
    opacity: 0.89;
    padding-right: 5px;
    padding-left: 10px;
    padding-top: 0;
  }
  p {
    line-height: 1.1;
    padding: 20px 0;
    text-align: left;
    font-size: 24px;
  }

  hr, hr:not([size]) {
    width: 100px;
    background-color: #0F0E35;
    height:5px;
    border-width:0;
    opacity: .65;
    margin: 50px 0;
  }
  .wallets {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-style: italic;
    white-space: nowrap;

    img {
      padding-left: 15px;
    }
  }
  .btn-outline-secondary {
    font-size: 21px;
    border-radius: 50px;
    font-weight: bold;
    border-color: #0F0E35;
    margin-top: 40px;
    padding: 10px 35px;
  }
}

.mint-mobile-btn {
  display: none !important;
}

.fixed-image {
  z-index: -1;
  min-height: 700px;
  .capanion-img {
    max-width: 60%;
    min-width: 50%;
    float: left;
    margin-top: -100px;
  }

}

.video-btn {
  cursor: pointer;
  color: #0F0E35;
  display: grid;
  margin-top: 20px;
  text-decoration: none;
  &:hover {
    color: #98C8F4;
  }
  svg {
    width: 55px;
    margin-left: 17px;
  }
  span {
    text-align: left;
  }
}

@media screen and (min-width:913px){
  .top-section .row {
    margin: 15% 0 -500px 10% !important;
  }
}

@media screen and (max-width:912px){

  .fixed-image {
    min-height: 500px;
    .capanion-img {
      max-width: 100%;
      margin-top: 50px;
    }
  }

  .right-section {
    margin: 15%!important;

    h2 {
      font-size: 45px;
    }

    p {
      font-size: 20px;
    }
  }

  .remove-col {
    display: none;
  }

  .hide {
    display: none !important;
  }

  .mint-mobile-btn {
    display: block !important;
    white-space: nowrap;
  }

  .right-section .wallets {
    font-size: 18px;
  }
}