.faq {
    padding: 50px 50px;
    .title {
        font-weight: bold;
        font-size: 2.5rem;
        padding: 50px 0 20px 0;
    }

    .accordion-item {
        margin: 25px 0;
        background: #FFFFFF;
        border: 3px solid #0F0E35;
        border-radius: 20px;
    }

    .accordion-item:not(:first-of-type) {
        border-top: 3px solid #0F0E35;
    }

    .accordion-body {
        text-align: left;;
    }

    .accordion-button {
        border-radius: 20px;;
        border: 0;
        font-weight: bold;
    }

    .accordion-item:last-of-type .accordion-button.collapsed, .accordion-item:last-of-type {
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .accordion-item:first-of-type {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .accordion-button:not(.collapsed) {
        color: #0F0E35;
        background-color: #fff;
        border: 0;
        box-shadow: none;
    }

    .accordion-button:not(.collapsed)::after  {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        float: right;
        color: inherit;
        box-shadow: 0;
    }
    .accordion-button::after  {
        /* symbol for "collapsed" panels */
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        box-shadow: 0;
    }

    .accordion-button:focus {
        border-color: transparent;
        outline: 0;
        box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
        border: 0;
    }
}