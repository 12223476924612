.contact {
    padding: 100px 0;
    .title {
        font-weight: bold;
        font-size: 2.5rem;
        padding: 50px 0 20px 0;
    }

    .whitelist {
        padding: 0 20%;
    }
    .input-sections {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        label {
            display: flex;
        }
        .col-md-4 {
            margin: 0 0 0 20px;
        }
        .padding-adjust {
            padding-right: 20px;
        }
    }

    .input-content {
        .form-control {
            display: block;
            width: 100%;
            padding: 1.25rem 0.75rem 0.5rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #F6F6F6;
            background-clip: padding-box;
            border: 0;
            border-bottom: 2px solid #0F0E35;
            border-radius: 0;
            -webkit-appearance: none;
            appearance: none;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.30s;
            &:focus {
                border: 0;
                border-bottom: 2px solid #98C8F4;
                box-shadow: none;
                background-color: #FAFAFA;
            }
            &:not(:focus) {
                border-bottom: 2px solid #0F0E35;
            }
        }
        .name {
            font-size: 1rem;
        }
        textarea {
            width: 100%;
            height: 150px;
            padding: 12px 20px;
            box-sizing: border-box;
            border-radius: 4px;
            background-color: #F6F6F6;
            border-bottom: 2px solid #0F0E35;
            resize: none;
            &:focus {
                border: 0;
                border-bottom: 2px solid #98C8F4;
                box-shadow: none;
                background-color: #FAFAFA;
            }
            &:not(:focus) {
                border-bottom: 2px solid #0F0E35;
            }
        }
    }
    .recaptcha {
        padding: 15px 0;
        display: flex;
        justify-content: center;
    }
    .btn {
        background-color: #0F0E35;
        color: #fff;
        border-color: #0F0E35;
        padding: 15px 70px;
        &:focus {
            color: #fff;
            background-color: #050349;
            border-color: #050349;
            box-shadow: 0 0 0 0.25rem rgba(49, 63, 253, 0.5);
        }
        &:hover {
            color: white;
            background-color: #06036d;
        }
    }
}