.modal {
    .modal-content {
        background: black;
    }

    .modal-header {
        border-bottom: 0px;
    }

    .modal-footer {
        border-top: 0px;
    }

    .close-btn {
        color: #fff; 
        opacity: 1;
        margin: 25px;
    }
}