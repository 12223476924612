.team {
    margin-right: 0;
    margin-left: 0;
    background-image: linear-gradient(white, #0F0E35);
    background-image: linear-gradient(white, #0F0E35);
    padding: 15px 0 40px 0;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    .title {
        font-weight: bold;
        font-size: 2.5rem;
        padding: 50px 0 20px 0;
    }

    .row > * {
        padding: 20px;
    }

    .team-content {
        h2 {
            font-weight: bold;
            font-size: 1.7rem;
            color: white;
        }
        img {
           background: #4b4b4b;
            border-radius: 10px;
            margin-bottom: 15px;
            min-width: 90%;
            width: 100%;
        }
        h2.role {
            font-weight: 200;
            font-size: 1rem;
            font-style: italic;
        }
        a {
            font-size: 35px;
            i {
                color: white;
            }
        }
    }
}

@media (max-width: 1068px) {

    .team .team-content img {
        width: 95%;
        min-width: 50%;
    }

}