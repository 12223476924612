@font-face {
  font-family: "Monda";
  src: local("Monda-Regular"),
  url("./fonts/Monda-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Monda";
  src: local("Monda-Bold"),
  url("./fonts/Monda-Bold.ttf") format("truetype");
  font-weight: bold;
}

@import "bootstrap/dist/css/bootstrap.css";


* {
  font-family: "Monda";
}

body {
  margin: 0;
  font-family: "Monda", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: whitesmoke;
}

.App {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
