$link-light-grey: #6c757d;
$primary-grey: #6c757d; //before: fed136
$footer-background-light-grey: #212529;
$footer-background-grey: #98C8F4;
$social-buttons-circle-dark-grey: #1a1d20;
$white: rgb(204, 204, 204);



::selection {
  background: $primary-grey;
  text-shadow: none;
}

footer {
    padding: 2rem 0;
    background-color: #0F0E35;
    z-index: 2;
    position: relative;
    width: 100%;

  a {
    color: $white;
    text-decoration: none;
  }

  .terms {
      margin-left: 15px;
  }
}

.footer-column:not(:first-child) {
    padding-top: 2rem;
    @media (min-width: 768px) {
      padding-top: 0rem;
    }
  }

.footer-column {
  text-align: center;
  //border: red solid 1px;
  .nav-item {
    .nav-footer-link {
      padding: 0.1rem 0;
    }
    span.nav-footer-link {
      color: $link-light-grey;
    }
    span.footer-title {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }
    .fas {
      margin-right: 0.5rem;
    }
  }
  ul {
    display: inline-block;
    @media (min-width: 768px) {
      text-align: left;
    }
    //border: yellow solid 1px;
  }
}

ul.social-buttons {
  margin-bottom: 0;
}


ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100%;
  outline: 0;
  background-color: $footer-background-grey;
}

footer .quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
}

.copyright {
  color: white;
}

.fa-ellipsis-h {
  color: white;
  padding: 2rem 0;
}
